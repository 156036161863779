/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useCallback} from "react";
// Chakra imports
import { Box } from "@chakra-ui/react";
import Sukelstable from "views/main/sukel/components/SukelsTable";
import Addsukel from "views/main/sukel/components/Addsukel";
import Banner from "views/main/sukel/components/Banner";

export default function Sukel() {
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, [refreshing]);
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Banner/><br/><Addsukel onRefresh={onRefresh}/><br/><Sukelstable refreshing={refreshing}/>
    </Box>
  );
}
