// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  useColorModeValue,
  Center,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
// Custom components
import Card from "components/card/Card.js";
import React, {useState} from "react";
import axios from "axios";
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function AddNosukel({onRefresh}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const [kodeinduk, setKodeinduk] = useState('');
  const [nomor, setNomor] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [statusConfPost, setStatusConfPost] = useState(false);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const cekadmin = decryptData(localStorage.getItem("authrolesitu"));
  const toast = useToast();

  const confPost = () => {
    if(kodeinduk===''||nomor===''||keterangan===''){
      toast({
        status: 'error',
        title: 'Proses Gagal',
        description: 'Masih ada kolom yang kosong',
        position: 'bottom-right',
        isClosable: true,
      });
    }else{
      setStatusConfPost(true);
    }
  }

  const exePost = async () => {
    setStatusConfPost(false);
    if(cekadmin === 'admin' || cekadmin === 'um'){
    await axios.post(URL_WEB_BACKEND+'/subnosukels', {
        kodeinduk : kodeinduk.toUpperCase().replace(/\s+/g,""),
        nomor: nomor,
        keterangan: keterangan.toLocaleUpperCase()}, 
      {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          onRefresh();setKodeinduk('');setNomor('');setKeterangan('');
          showInfoDialog('Berhasil','Sub Kode surat berhasil disimpan.', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Menyimpan Sub Kode Surat dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Menyimpan Sub Kode Surat dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }
  
  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Flex direction='column'>
        {viewInfoDialog()}
        <Modal closeOnOverlayClick={false} isCentered isOpen={statusConfPost}>
            <ModalOverlay
              bg='blackAlpha.300'
              backdropFilter='blur(10px) hue-rotate(10deg)'
            />
            <ModalContent>
              <ModalHeader>Simpan Sub Kode</ModalHeader>
              <ModalBody>
                <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
                <Text fontSize='large'>Simpan sub kode nomor surat?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {exePost()}}>
                  Iya, Lanjutkan
                </Button>
                <Button onClick = {() => {setStatusConfPost(false)}}>Batal</Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "5px", "2xl": "5px" }}>
            Tambah Sub Kode
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            textAlign='start'>
            Tambah sub kode nomor surat keluar.
          </Text>
          <Flex w='100%' mt={{ base: "20px", "2xl": "20px" }} >
          <FormControl id="profil" isRequired>
          <FormLabel>Kode Induk</FormLabel>
            <Input id='subnosukel1' placeholder="Masukkan kode induk" borderRadius="16px" value={kodeinduk} onChange={ (e) => setKodeinduk(e.target.value) } />
            <FormLabel>Nomor</FormLabel>
            <Input id='subnosukel2' placeholder="Masukkan nomor" borderRadius="16px" value={nomor} onChange={ (e) => setNomor(e.target.value) } />
            <FormLabel>Keterangan</FormLabel>
            <Input id='subnosukel3' placeholder="Masukkan keterangan" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
            <Button
              me='100%'
              mb='50px'
              w='140px'
              minW='140px'
              mt={{ base: "20px", "2xl": "10px" }}
              variant='brand'
              fontWeight='500'
              onClick={confPost}>
              Simpan
            </Button>
          </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
