/* eslint-disable */
import {
  Flex,
  Text,
  useColorModeValue,
  Grid,
  GridItem,
  Select,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useState, useEffect } from "react";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import Autosuggest from 'react-autosuggest';
import { HSeparator } from "components/separator/Separator";

export default function ChoosePetugas({refreshing, onSetDetail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const toast = useToast();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  // search
  const [searchClue, setSearchClue] = useState('');
  const [searchCat, setSearchCat] = useState('username');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
      if(refreshing){
        //
      }
    },[refreshing]);

  const catOpt = [
    { value: 'username', label: 'NIP' },
    { value: 'fullname', label: 'Nama Lengkap' },
  ];

  const getSuggestions = async (value) => {
    if (!value) {
      return [];
    }

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return [];
    }

    const response = await axios.get(URL_WEB_BACKEND + '/usersearch/' + searchCat + '/' + inputValue, {
      headers: {
        'Authorization': 'Bearer ' + authdata
      }
    });

    if (searchCat === 'username') {
      return Array.isArray(response.data.username) ? response.data.username : [response.data.username];
    } else {
      return Array.isArray(response.data.fullname) ? response.data.fullname : [response.data.fullname];
    }
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = await getSuggestions(value);
    setSuggestions(Array.isArray(suggestions) ? suggestions : []);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setSearchClue(suggestion);
    axios.get(URL_WEB_BACKEND + '/usersearch/' + searchCat + '/' + suggestion, {
      headers: { 'Authorization': 'Bearer ' + authdata }
    }).then((response) => {
      const userData = response.data;
      getDataPenghasilan(userData); 
  });
};

const getDataPenghasilan = async (userData) => {
  await axios.get(URL_WEB_BACKEND+'/penghasilans/'+userData.userid, {
    headers: {
      'Authorization': 'Bearer '+authdata
    }
  }).then((response) => {
    const penghasilanData = response.data;
    onSetDetail({ ...userData, penghasilan: penghasilanData });
  }).catch(err => {
    onSetDetail({ ...userData, penghasilan: [] });
    toast({
      status: 'error',
      title: 'Error status '+err.response.status,
      description: 'Data penghasilan kosong.',
      position: 'bottom-right',
      isClosable: true,
    });
  });
};

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion}
    </div>
  );

  const inputProps = {
    placeholder: "Cari",
    value: searchClue,
    onChange: (e, { newValue }) => setSearchClue(newValue),
  };

  const theme = {
    container: {
      position: 'relative',
    },
    input: {
      width: '100%',
      height: '40px',
      padding: '10px',
      fontFamily: 'Arial, sans-serif',
      fontWeight: 300,
      fontSize: '16px',
      border: '1px solid #ddd',
      borderRadius: '16px',
    },
    suggestionsContainer: {
      display: 'none',
    },
    suggestionsContainerOpen: {
      display: 'block',
      position: 'absolute',
      top: '100%',
      width: '100%',
      border: '1px solid #ddd',
      backgroundColor: textColorPrimary,
      fontFamily: 'Arial, sans-serif',
      fontWeight: 300,
      fontSize: '16px',
      borderRadius: '16px',
      zIndex: 2,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd',
      borderRadius: '16px',
    },
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Cari Data Petugas :
        </Text>
        <Grid templateColumns="repeat(6, 1fr)" gap={2}>
          <Select borderRadius='16px' aria-label="Default" value={searchCat} onChange={ (e) => setSearchCat(e.target.value) }>
            {catOpt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
          </Select>
          <GridItem colSpan='5'>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={(suggestion) => suggestion}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              theme={theme}
            />
          </GridItem>
        </Grid>
      </Flex>
      <br/><HSeparator/><br/>
    </Card>
  );
}