// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  Grid,
  GridItem,
  Center,
  Spinner,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
// Custom components
import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
import React, {useState, useEffect} from "react";
import axios from "axios";
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function PengTable({onRefresh, refreshing, detail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const bgDynColor = useColorModeValue(
      "#EBF8FF !important",
      "#1A365D !important"
  );

  const [statusLoading, setStatusLoading] = useState(false);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [showDetailPenghasilan, setShowDetailPenghasilan] = useState([]);

  const [penghasilan, setPenghasilan] = useState([]);
  const [potongan, setPotongan] = useState([]);

  useEffect(() => {
      setStatusLoading(true);
      if(!refreshing){
        setStatusLoading(false);
        setShowDetailPenghasilan(detail.penghasilan);
      }
    },[detail]);
  
    useEffect(() => {
      if(refreshing){
        refreshData();
      }
    },[refreshing]);

    const refreshData = async () =>{
      setStatusLoading(true);
      await axios.get(URL_WEB_BACKEND+'/penghasilans/'+detail.userid, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            setShowDetailPenghasilan(res.data);
            setStatusLoading(false);
          }else{
            setStatusLoading(true);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data penghasilan dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusLoading(true);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data penghasilan dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  const totalPenghasilan = penghasilan.reduce((sum, item) => sum + parseInt(item.nominal), 0);
  const totalPotongan = potongan.reduce((sum, item) => sum + parseInt(item.nominal), 0);
  const totalAkhir = totalPenghasilan - totalPotongan;

  useEffect(() => {
    setPenghasilan(showDetailPenghasilan.filter((item) => item.jenis === "1"));
    setPotongan(showDetailPenghasilan.filter((item) => item.jenis === "0"));
  }, [showDetailPenghasilan]);

  const addPenghasilan = () => {
    setPenghasilan([...penghasilan, { id: null, keterangan: "", nominal: 0, jenis: "1" }]);
  };
  
  const addPotongan = () => {
    setPotongan([...potongan, { id: null, keterangan: "", nominal: 0, jenis: "0" }]);
  };

  const removeItem = async (index, type) => {
    if (type === "penghasilan") {
      const itemToRemove = penghasilan[index];
      if (itemToRemove.id) {
        try {
          await axios.delete(`${URL_WEB_BACKEND}/penghasilans/${itemToRemove.id}`, {
            headers: {
              'Authorization': 'Bearer ' + authdata
            }
          });
          onRefresh();
        } catch (error) {
          alert("Gagal menghapus data");
          console.error("Error deleting data:", error);
        }
      }
      setPenghasilan(penghasilan.filter((_, i) => i !== index));
    } else {
      const itemToRemove = potongan[index];
      if (itemToRemove.id) {
        try {
          await axios.delete(`${URL_WEB_BACKEND}/penghasilans/${itemToRemove.id}`, {
            headers: {
              'Authorization': 'Bearer ' + authdata
            }
          });
          onRefresh();
        } catch (error) {
          alert("Gagal menghapus data");
          console.error("Error deleting data:", error);
        }
      }
      setPotongan(potongan.filter((_, i) => i !== index));
    }
  };

  const saveData = async () => {
    const existingData = [...penghasilan, ...potongan].filter(item => item.id);
    const newData = [...penghasilan, ...potongan].filter(item => !item.id);

    try {
        for (let item of existingData) {
            let formData = new FormData();
            formData.append('id', item.id);
            formData.append('id_user', detail.userid);
            formData.append('jenis', item.jenis);
            formData.append('keterangan', item.keterangan);
            formData.append('nominal', parseInt(item.nominal) || 0);

            await axios.post(`${URL_WEB_BACKEND}/updatedatapenghasilan`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authdata,
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        for (let item of newData) {
            let formData = new FormData();
            formData.append('id_user', detail.userid);
            formData.append('jenis', item.jenis);
            formData.append('keterangan', item.keterangan);
            formData.append('nominal', parseInt(item.nominal) || 0);

            await axios.post(`${URL_WEB_BACKEND}/penghasilans`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authdata,
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        onRefresh();
        showInfoDialog('Berhasil', 'Data penghasilan berhasil disimpan', 0);
    } catch (error) {
        console.error("Error saving data:", error);
        showInfoDialog('Gagal', 'Data penghasilan gagal disimpan', 2);
    }
  };

  const formatRupiah = (value) => {
    if (!value) return "";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };
  
  const handleNominalChange = (e, index, type) => {
    let rawValue = e.target.value.replace(/\D/g, ""); // Hanya angka
    let newData = type === "penghasilan" ? [...penghasilan] : [...potongan];
    newData[index].nominal = rawValue;
    type === "penghasilan" ? setPenghasilan(newData) : setPotongan(newData);
  };
  
  const handleFocus = (e) => {
    e.target.value = e.target.value.replace(/\D/g, ""); // Kembalikan ke angka asli saat input aktif
  };
  
  const handleBlur = (e, index, type) => {
    let newData = type === "penghasilan" ? [...penghasilan] : [...potongan];
    e.target.value = formatRupiah(newData[index].nominal); // Format ulang saat input kehilangan fokus
  };

  const logoURL = "https://upload.wikimedia.org/wikipedia/commons/0/00/Logo_Kementrian_Imigrasi_dan_Pemasyarakatan_%282024%29.png";
  
    const getBase64ImageFromURL = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };
  
    const getBulanTahun = () => {
      const now = new Date();
      const bulan = now.toLocaleString("id-ID", { month: "long" });
      const tahun = now.getFullYear();
      return `BULAN ${bulan.toUpperCase()} ${tahun}`;
    };
  
    const getTanggalSekarang = () => {
      const now = new Date();
      const tanggal = now.toLocaleString("id-ID", { day: "numeric", month: "long", year: "numeric" });
      return `Semarang, ${tanggal}`;
    };
  
    const generatePDF = async  () => {
      const doc = new jsPDF();
  
      const logoBase64 = await getBase64ImageFromURL(logoURL);
  
      doc.addImage(logoBase64, "PNG", 80, 10, 35, 30);
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text("LEMBAGA PEMASYARAKATAN KELAS I SEMARANG", 105, 50, { align: "center" });
      doc.setFontSize(12);
      doc.text("SURAT KETERANGAN PENGHASILAN", 105, 60, { align: "center" });
      doc.text(getBulanTahun(), 105, 68, { align: "center" });
    
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Nama: ${detail.fullname.toUpperCase()}`, 20, 80);
      doc.text(`NIP: ${detail.username.toUpperCase()}`, 20, 88);
    
      let yPos = 100;
      doc.setFont("helvetica", "bold");
      doc.text("I. PENGHASILAN", 20, yPos);
      doc.setFont("helvetica", "normal");
    
      penghasilan.forEach((item) => {
        yPos += 8;
        doc.text(`${item.keterangan}:`, 20, yPos);
        doc.text(formatRupiah(item.nominal), 150, yPos, { align: "right" });
      });
    
      yPos += 10;
      doc.setFont("helvetica", "bold");
      doc.text("JUMLAH PENGHASILAN:", 20, yPos);
      doc.text(formatRupiah(totalPenghasilan), 150, yPos, { align: "right" });
    
      yPos += 15;
      doc.setFont("helvetica", "bold");
      doc.text("II. POTONGAN", 20, yPos);
      doc.setFont("helvetica", "normal");
    
      potongan.forEach((item) => {
        yPos += 8;
        doc.text(`${item.keterangan}:`, 20, yPos);
        doc.text(formatRupiah(item.nominal), 150, yPos, { align: "right" });
      });
    
      yPos += 10;
      doc.setFont("helvetica", "bold");
      doc.text("JUMLAH POTONGAN:", 20, yPos);
      doc.text(formatRupiah(totalPotongan), 150, yPos, { align: "right" });
    
      yPos += 15;
      doc.setFont("helvetica", "bold");
      doc.text("III. TOTAL PENGHASILAN:", 20, yPos);
      doc.text(formatRupiah(totalAkhir), 150, yPos, { align: "right" });
    
      yPos += 30;
      doc.text(getTanggalSekarang(), 140, yPos);
      yPos += 20;
      doc.text("BINA AJI SATRIA", 140, yPos);
      yPos += 5;
      doc.text("NIP. 199305162021211001", 140, yPos);
    
      doc.save(`Surat_Keterangan_Penghasilan_${detail.username}.pdf`);
    };

  const viewInfoDialog = () => {
      return (
      <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
            <ModalOverlay
              bg='blackAlpha.300'
              backdropFilter='blur(10px) hue-rotate(10deg)'
            />
            <ModalContent>
              <ModalHeader>{titleDialog}</ModalHeader>
              <ModalBody>
                <Center>
                  {infoDialogIcon
                    ? <InfoIcon w={16} h={16} color="blue.500" />
                    : <CheckIcon w={16} h={16} color="green.500" />
                  }
                </Center>
                <Center mt={8}>
                  <Text fontSize='large'>{descDialog}</Text>
                </Center>
              </ModalBody>
              <ModalFooter>
                <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
      )
    }

  return (
    <Card align='center' p='20px'>
      {viewInfoDialog()}
      {statusLoading.length?<Center mb={6}><Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/></Center>:
        <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
          <Flex direction='column' w={'100%'}>
            <Text
              color={textColorPrimary}
              fontWeight='bold'
              textAlign='start'
              fontSize='2xl'
              mt={{ base: "5px", "2xl": "5px" }}>
              Rincian Data Penghasilan
            </Text>
            <Text
              color={textColorSecondary}
              fontSize='md'
              my={{ base: "auto", "2xl": "10px" }}
              textAlign='start'>
              berisi semua penghasilan dan potongan-potongan.
            </Text>
            <Text
              color={textColorPrimary}
              fontSize='md'
              my={{ base: "auto", "2xl": "10px" }}
              textAlign='start'>
                  NIP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{detail.username.toUpperCase()}
            </Text>
            <Text
              color={textColorPrimary}
              fontSize='md'
              my={{ base: "auto", "2xl": "10px" }}
              textAlign='start'>
                  Nama&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{detail.fullname.toUpperCase()}
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem p={5}>
              <FormLabel>I. PENGHASILAN</FormLabel>
              {penghasilan.map((item, index) => (
                <Flex key={index} alignItems='center'>
                  <Input placeholder='Keterangan' borderRadius={16}
                    marginBottom={5} textColor={textColorPrimary}
                    value={item.keterangan} onChange={(e) => {
                    const newData = [...penghasilan];
                    newData[index].keterangan = e.target.value;
                    setPenghasilan(newData);
                  }} />
                  <Input
                    placeholder="Nominal"
                    borderRadius={16}
                    marginBottom={5}
                    marginStart={3}
                    textColor={textColorPrimary}
                    defaultValue={formatRupiah(item.nominal)}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, index, "penghasilan")}
                    onChange={(e) => handleNominalChange(e, index, "penghasilan")}
                  />
                  <Button colorScheme='red' marginBottom={5} marginStart={3} onClick={() => removeItem(index, "penghasilan")}>
                    X
                  </Button>
                </Flex>
              ))}
              <Button colorScheme='blue' onClick={addPenghasilan}>+ Penghasilan</Button>
            </GridItem>
            <GridItem bgColor={bgDynColor} p={5} borderRadius={16}>
              <FormLabel>II. POTONGAN</FormLabel>
              {potongan.map((item, index) => (
                <Flex key={index} alignItems='center'>
                  <Input placeholder='Keterangan' borderRadius={16}
                    marginBottom={5} textColor={textColorPrimary}
                    
                    value={item.keterangan} onChange={(e) => {
                    const newData = [...potongan];
                    newData[index].keterangan = e.target.value;
                    setPotongan(newData);
                  }} />
                  <Input
                    placeholder="Nominal"
                    borderRadius={16}
                    marginBottom={5}
                    marginStart={3}
                    textColor={textColorPrimary}
                    defaultValue={formatRupiah(item.nominal)}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, index, "potongan")}
                    onChange={(e) => handleNominalChange(e, index, "potongan")}
                  />
                  <Button colorScheme='red' marginBottom={5} marginStart={3} onClick={() => removeItem(index, "potongan")}>
                    X
                  </Button>
                </Flex>
              ))}
              <Button colorScheme='blue' onClick={addPotongan}>+ Potongan</Button>
            </GridItem>
          </Grid>
          <HSeparator my={4} />
          <FormControl>
            <FormLabel>III. JUMLAH PENGHASILAN</FormLabel>
            <Input textColor={textColorPrimary} value={formatRupiah(totalPenghasilan)} marginBottom={5} border='2px dashed' readOnly/>
            <FormLabel>IV. JUMLAH POTONGAN</FormLabel>
            <Input textColor={textColorPrimary} value={formatRupiah(totalPotongan)} marginBottom={5} border='2px dashed' readOnly/>
            <FormLabel>V. TOTAL PENGHASILAN</FormLabel>
            <Input textColor={textColorPrimary} value={formatRupiah(totalAkhir)} marginBottom={5} border='2px dashed' readOnly/>
            <Button w='100%' colorScheme='blue' mt={4} onClick={()=>saveData()}>SIMPAN PERUBAHAN</Button>
            <Button w='100%' colorScheme='blue' mt={4} onClick={generatePDF}>UNDUH DATA</Button>
          </FormControl>
          </Flex>
        </Flex>}
    </Card>
  );
}
