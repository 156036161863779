/* eslint-disable */
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  useToast,
  Grid,
  Box,
  Spinner,
  Image,
  Badge
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon, ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { convertDate, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import { URL_WEB_FORNTEND } from "CustomUtil";
import { saveAs } from "file-saver";

export default function Detail({refreshing, detail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [imageHashToRefresh, setImageHashToRefresh] = useState(new Date().getTime());

  const [showDetail, setShowDetail] = useState([]);

  const [adminList, setAdminList] = useState([]);

  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const qrCodeRef = useRef();

  useEffect(() => {
    setStatusLoading(true);
    getAdminList();
    if(!refreshing){
      setStatusLoading(false);
      setShowDetail(detail);
    }
  },[detail]);

  useEffect(() => {
    if(refreshing){
      refreshData();
    }
  },[refreshing]);

  const refreshData = async () =>{
    setStatusLoading(true);
    await axios.get(URL_WEB_BACKEND+'/bmns/'+showDetail.id, {
      headers: {
        'Authorization': 'Bearer '+authdata,
        'content-type': 'application/ x-www-form-urlencoded'
      }}).then(res => {
        if(res.status===200){
          setShowDetail(res.data);
          setStatusLoading(false);
          setImageHashToRefresh(new Date().getTime());
        }else{
          setStatusLoading(false);
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        setStatusLoading(false);
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error mendapatkan data bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const getAdminList = async () =>{
    await axios.get(URL_WEB_BACKEND+'/admins', {
      headers: {
        'Authorization': 'Bearer '+authdata,
        'content-type': 'application/ x-www-form-urlencoded'
      }}).then(res => {
        if(res.status===200){
          setAdminList(res.data);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data admin dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error mendapatkan data admin dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeLapor = async (title, desc) =>{
    setStatusUploading(true);setStatusConfExeDialog(false);
    for(let i = 0; i < adminList.length; i++){
      await axios.post(URL_WEB_BACKEND+'/notifs',{
        id_user : adminList[i].id,
        judul: title,
        isi: desc,
        tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
        read : 0
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===201){
            console.log('Notif send to '+adminList[i].id);
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Mengirim Laporan dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Mengirim Laporan dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        });
        if(adminList.length - 1 === i) {
          setStatusUploading(false);
          showInfoDialog('Berhasil', 'Laporan berhasil dikirim ke seluruh Admin.', 0);
        }
    }
  }

  const downloadQRCode = async (namafile) => {
    const element = qrCodeRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = namafile+'.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => exeLapor('Laporan BMN', detail.nama+' dilaporkan RUSAK BERAT / HABIS oleh '+namanya+'. Mohon segera ditindaklanjuti.')}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' align='center'>
      {viewInfoDialog()}{viewConfExeDialog()}{viewUploadingDialog()}
      <Text
        color={textColor}
        fontSize='2xl'
        fontWeight='700'
        lineHeight='100%'>
          Detail Barang <ArrowForwardIcon ms='1'/> {showDetail.nama}
        {showDetail.tersedia==='1'?<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="green">Aktif</Badge>:<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="red">Non-aktif</Badge>}
        {showDetail.jenis==='tetap'?<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="purple">Aset Tetap</Badge>:<Badge ms='2' textAlign='start' fontSize="0.7em" variant="outline" colorScheme="purple">Persediaan</Badge>}
      </Text>
      <Stack direction='row'>
      <Button
        colorScheme='red'
        onClick={()=>showConfExeDialog('Laporkan BMN Rusak Berat / Stok Habis','Kirim laporan ke semua Admin dan Subbag Umum.')}>
        <Text
          fontSize='lg'
          as='b'>
            Laporkan Rusak Berat / Stok Habis
        </Text>
      </Button>
      </Stack>
      </Flex>
      {statusLoading.length?<Center mb={6}><Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/></Center>:
      <Box mt='10px' px='25px' w='100%'>
        <Stack direction="row">
          <Image me='5px' boxShadow="lg" borderRadius='10px' alt='Gambar Aset BMN' maxH='450' src={URL_WEB_BACKEND+"/uploads/bmns/foto/"+showDetail.verifid+'.'+showDetail.extfoto+'?t='+imageHashToRefresh} />
          <Box w='100%'>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                <Box w='100%'>
                  <Text as='b'>LETAK</Text><HSeparator mb='1'/>{showDetail.fullname}<br/><br/>
                </Box>
                <Box w='100%'>
                  <Text as='b'>NOMOR URUT PENDAFTARAN</Text><HSeparator mb='1'/>{showDetail.noreg}<br/><br/>
                </Box>
              </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>TANGGAL</Text><HSeparator mb='1'/>{convertDate(showDetail.tanggal)}<br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>JUMLAH</Text><HSeparator mb='1'/>{showDetail.jumlah} {showDetail.satuan}<br/><br/>
              </Box>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>KONDISI</Text><HSeparator mb='1'/>{showDetail.kondisi}<br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>ASAL PEROLEHAN</Text><HSeparator mb='1'/>{showDetail.sumber}<br/><br/>
              </Box>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>LINK VERIFIKASI</Text><HSeparator mb='1'/><Box onClick={()=>downloadQRCode('qrcode verifikasi bmn '+showDetail.nama)} as="button" ref={qrCodeRef} borderRadius="16px" bg="white" color="white" p={4} h={200}><QRCode size={160} value={URL_WEB_FORNTEND+'/#/verifikasi/bmn/'+showDetail.verifid} /></Box><br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>FILE ADMINISTRATIF</Text><HSeparator mb='1'/><Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/bmns/file/"+showDetail.verifid+'.'+showDetail.extfile, 'BMN-'+showDetail.noreg+'.'+showDetail.extfile)}><DownloadIcon/> LIHAT DISINI</Button><br/><br/>
              </Box>
            </Grid>
          </Box>
        </Stack>
      </Box>}
    </Card>
  );
}
