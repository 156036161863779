import React, { useState, useEffect } from "react";

// Chakra imports
import { Flex, Text, Grid, Stack, Button, Input } from "@chakra-ui/react";

import axios from "axios";

// Assets
import banner from "assets/img/dashboards/Debit.png";

import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function Banner() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const cekrole = decryptData(localStorage.getItem("authrolesitu"));
  const [adaStatus, setAdaStatus] = useState(true);
  const [plhStatus, setPlhStatus] = useState(true);
  const [statusKalapasValue, setStatusKalapasValue] = useState('');
  const [namaPejabat, setNamaPejabat] = useState('');

  const setStatusKalapas = (status) => {
    if(cekrole === 'admin' || cekrole === 'tu' || cekrole === 'keu' || cekrole === 'um'){
      axios.put(URL_WEB_BACKEND+'/infos/0',{
        judul: status.toUpperCase(),
        isi: namaPejabat,
        tanggal: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ')
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            setStatusKalapasValue(status.toUpperCase());
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            console.log('Error Perbarui dengan status '+res.response.status, res.response.messages[reskey])
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          console.log('Error Perbarui dengan status '+err.response.status, err.response.data.messages[errkey])
        })
    }else{
      console.log('Error Pembaruan','Anda tidak memiliki hak akses untuk ini.')
    }
  };

  const getStatusKalapas = () => {
    setAdaStatus(true);setPlhStatus(true);
    axios.get(URL_WEB_BACKEND+'/infos/0', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setNamaPejabat(response.data.isi);
      setStatusKalapasValue(response.data.judul);
      if(response.data.judul==='ADA'){
        setAdaStatus(false);setPlhStatus(true);
      }else{
        setAdaStatus(true);setPlhStatus(false);
      }
    }).catch(err => {
      console.log('Error Mendapatkan Data Status Kalapas '+err.response.status)
    });
  };

  useEffect(() => {
    getStatusKalapas();
  },[]);

  return (
    <Flex
      direction='column'
      bgImage={banner}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
        <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Status Kalapas : 
        </Text>
        <Stack>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Button onClick={()=>{setPlhStatus(false);setAdaStatus(true);setStatusKalapas('plh');}} disabled={adaStatus}>ADA</Button>
            <Button onClick={()=>{setAdaStatus(false);setPlhStatus(true);setStatusKalapas('ada');}} disabled={plhStatus}>PLH</Button>
          </Grid>
          <Text
          fontSize={{ base: "14px", md: "24px" }}
          color='white'
          mb='14px'
          fontWeight='300'
          lineHeight={{ base: "32px", md: "42px" }}>
          Bapak 
          </Text>
          <Input textColor='white' id={"namaPejabat"} placeholder="Nama Pejabat" borderRadius="16px" value={namaPejabat} onChange={ (e) => setNamaPejabat(e.target.value) } onKeyPress={(e) => {if(e.key === 'Enter'){setStatusKalapas(statusKalapasValue)}}} />
        </Stack>
    </Flex>
  );
}
