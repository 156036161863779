/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

*/

import React, { useState } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// Mine
import axios from 'axios'
import { URL_WEB_BACKEND, encryptData } from '../../../CustomUtil'
import jwtDecode from 'jwt-decode'

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // mine
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [statusLoading, setStatusLoading] = useState(false);
  const toast = useToast();

  const loginHandler = async () => {
    if (username === "" || password === "") {
      toast({
        status: 'error',
        title: 'Login Gagal !',
        description: 'Masih ada kolom yang kosong',
        position: 'top-right',
        isClosable: true,
      });
    }else{
      setStatusLoading(true);
      const topost = { username: username, password: password };
      await axios.post(URL_WEB_BACKEND+'/login', topost)
        .then(response => {
          if(response.data.token !== null){
            var jwt = jwtDecode(response.data.token);
            localStorage.setItem("authinfositu", encryptData(response.data.token));
            localStorage.setItem("authidsitu", encryptData(jwt.userid));
            localStorage.setItem("authfullnamesitu", encryptData(jwt.fullname));
            localStorage.setItem("authrolesitu", encryptData(jwt.role));
            setTimeout(() => {
              setStatusLoading(false);
              for(let i = 0; i < 2; i++){
                window.location.reload();
              }
            }, 1000)
          }else{
            setStatusLoading(false);
            let reskey = Object.keys(response.response.data.messages)[0];
            toast({
              status: 'error',
              title: 'ERROR',
              description: 'Error '+$(response.response.status, response.response.messages[reskey]),
              position: 'top-right',
              isClosable: true,
            });
          }
        })
        .catch(error => {
          setStatusLoading(false);
          if (error.response.status===500){
            toast({
              status: 'error',
              title: 'ERROR',
              description: 'Server sibuk!',
              position: 'top-right',
              isClosable: true,
            });
          }else{
            toast({
              status: 'error',
              title: 'Login Gagal !',
              description: 'username / NIP atau password salah',
              position: 'top-right',
              isClosable: true,
            });
          }
        })
    }
  };

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'username') {
      setUsername(value);
    }else{
      setPassword(value);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusLoading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
          </ModalContent>
      </Modal>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Masuk
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Ke Aplikasi SITU, gunakan akun Anda
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Username<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='masukkan username / NIP'
              mb='24px'
              fontWeight='500'
              size='lg'
              id="username"
              name="username"
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress={(e) => {if(e.key === 'Enter'){loginHandler()}}}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='masukkan password'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                id="password"
                name="password"
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress={(e) => {if(e.key === 'Enter'){loginHandler()}}}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <HSeparator mr='4'/>
              </FormControl>
              <a href="https://wa.me/6285869145466?text=Bal,%20Aku%20lupa%20password%20aplikasi%20SITU" target="_blank">
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Lupa password?
                </Text>
              </a>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick = {() => {loginHandler()}} >
              <Flex
                align='center'
                ps={{ base: "25px", lg: "0px" }}
                pt={{ lg: "0px", xl: "0px" }}
                w='fit-content'>
                  <Text ms='0px' fontSize='xl'>
                    Lanjut
                  </Text>
                <Icon
                  as={FaChevronRight}
                  ms='10px'
                  h='14px'
                  w='12px'
                />
              </Flex>
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
