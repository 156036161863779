// Chakra imports
import { Avatar, Box, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, job} = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const bgDynColor = useColorModeValue(
    "#EBF8FF !important",
    "#1A365D !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        name={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
        bg='#11047A'
        color="white"
      />
      <Box
        border='1px dashed'
        bg={bgDynColor}
        mt='10'
        bgSize='cover'
        borderRadius='16px'
        h='100%'
        w='100%'
      >
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='50px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='md'>
        {job}
      </Text>
      </Box>
    </Card>
  );
}
