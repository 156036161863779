/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import React, {useState, useCallback} from "react";
import { Box } from "@chakra-ui/react";
import ChoosePetugas from "views/main/suketpengkeu/components/ChoosePetugas";
import Banner from "views/main/suketpengkeu/components/Banner";
import PengTable from 'views/main/suketpengkeu/components/PengTable';

export default function SuketPengKeu() {
  const [detail, setDetail] = useState([]);
  const [showData, setShowData] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
      setRefreshing(true);
      setTimeout(() => {
        setRefreshing(false);
      }, 1000);
    }, [refreshing]);
  
    const onSetDetail = useCallback(async (data) => {
      if(data===undefined){
        setShowData(false);
      }else{
        setDetail(data);
        setShowData(true);
      }
    }, [detail]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Banner/><br/><ChoosePetugas refreshing={refreshing} onSetDetail={onSetDetail}/><br/>{showData?<PengTable onRefresh={onRefresh} refreshing={refreshing} detail={detail} />:<></>}
    </Box>
  );
}
