/* eslint-disable */
import {
  Text,
  useColorModeValue,
  Button,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Box,
  Spinner,
  Select,
  Textarea,
  Spacer,
  Table,
  Tbody,
  Thead,
  TableCaption,
  Td,
  Tr,
  Th,
  Grid
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { CheckIcon, InfoIcon, QuestionIcon, DownloadIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from "react";
import { convertDateTime, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import axios from "axios";
import { saveAs } from "file-saver";
import { HSeparator } from "components/separator/Separator";
import { useReactToPrint } from "react-to-print";

export default function TxDetail({onRefresh, refreshing, detail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgGradient = useColorModeValue("linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(217,217,217,1) 23%, rgba(191,186,208,1) 100%)", "linear-gradient(90deg, rgba(33,33,33,1) 0%, rgba(50,49,49,1) 23%, rgba(89,84,106,1) 100%)");
  const textColorSecondary = "gray.400";

  const [idDetail, setIdDetail] = useState(0);
  const [tersedia, setTersedia] = useState(1);
  const [showTxDetail, setShowTxDetail] = useState([]);
  const [showCetakTxDetail, setShowCetakTxDetail] = useState([]);

  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [statusTransferInternDialog, setStatusTransferInternDialog] = useState(false);
  const [statusTransferExternDialog, setStatusTransferExternDialog] = useState(false);
  const [statusPenambahanDialog, setStatusPenambahanDialog] = useState(false);
  const [statusPemanfaatanDialog, setStatusPemanfaatanDialog] = useState(false);
  const [statusPenggunaanDialog, setStatusPenggunaanDialog] = useState(false);
  const [statusPenonaktifanDialog, setStatusPenonaktifanDialog] = useState(false);
  const [statusCetakDialog, setStatusCetakDialog] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const toast = useToast();
  const componentToPrintRef = useRef();

  const [searchJenisTx, setSearchJenisTx] = useState('semua');

  const [addJenisTx, setAddJenisTx] = useState('');
  const [kondisi, setKondisi] = useState('baik');
  const [tanggalTx, setTanggalTx] = useState(new Date());
  const [in_id_user_sebelum, setIn_id_user_sebelum] = useState();
  const [in_id_user_sesudah, setIn_id_user_sesudah] = useState();
  const [fullname_sebelum, setFullname_sebelum] = useState();
  const [fullname_sesudah, setFullname_sesudah] = useState();
  const [jumlah_transaksi, setJumlah_transaksi] = useState(1);
  const [jumlah_sesudah, setJumlah_sesudah] = useState(1);
  const [keterangan, setKeterangan] = useState('');
  const [filenya, setFilenya] = useState(null);

  const [userList, setuserList] = useState([]);
  
  const [filterTanggalMulai, setFilterTanggalMulai] = useState(new Date());
  const [filterTanggalSampai, setFilterTanggalSampai] = useState(new Date());

  const persediaanJenisTxOPt = [
    { value: 'penggunaan', label: 'Penggunaan' },
    { value: 'penambahan', label: 'Penambahan' },
    { value: 'penonaktifan', label: 'Penonaktifan' },
  ];

  const tetapJenisTxOPt = [
    { value: 'transfer_internal', label: 'Transfer Internal' },
    { value: 'transfer_eksternal', label: 'Transfer Eksternal' },
    { value: 'penambahan', label: 'Penambahan' },
    { value: 'pemanfaatan', label: 'Pemanfaatan' },
    { value: 'peminjaman', label: 'Peminjaman' },
    { value: 'pengembalian', label: 'Pengembalian' },
    { value: 'penonaktifan', label: 'Penonaktifan' },
  ];
  
  const jenisTxOPtSearch = [
    { value: 'semua', label: 'Semua Jenis Transaksi' },
    { value: 'penggunaan', label: 'penggunaan' },
    { value: 'penambahan', label: 'Penambahan' },
    { value: 'transfer_internal', label: 'Transfer Internal' },
    { value: 'transfer_eksternal', label: 'Transfer Eksternal' },
    { value: 'pemanfaatan', label: 'Pemanfaatan' },
    { value: 'peminjaman', label: 'Peminjaman' },
    { value: 'pengembalian', label: 'Pengembalian' },
    { value: 'penonaktifan', label: 'Penonaktifan' },
  ];

  const kondisiOPt = [
    { value: 'baik', label: 'Baik' },
    { value: 'rusak ringan', label: 'Rusak Ringan' },
    { value: 'rusak berat', label: 'Rusak Berat' },
  ];

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  useEffect(() => {
    if(!refreshing){
      setIdDetail(detail.id);
      setTersedia(parseInt(detail.tersedia));
      setKondisi(detail.kondisi);
      setIn_id_user_sebelum(detail.in_id_user);
      setFullname_sebelum(detail.fullname);
      setIn_id_user_sesudah(detail.in_id_user);
      setFullname_sesudah(detail.fullname);
      if(detail.jenis==='tetap'){
        setAddJenisTx('transfer_internal')
      }else{
        setAddJenisTx('penggunaan')
      }
      getTxData(detail.id, currentPageNumber);
    }
    getUserList();
  },[detail]);

  useEffect(() => {
    getTxData(detail.id, currentPageNumber);
  },[searchJenisTx]);

  useEffect(() => {
    setTimeout(() => {
      getCetakTxData(detail.id);
    }, 600)
  },[filterTanggalMulai, filterTanggalSampai]);

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const getUserList = () => {
    axios.get(URL_WEB_BACKEND+'/usersnonpaginate', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setuserList(response.data.map(user => ({label: user.fullname, value: user.id})));
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data daftar user kosong.',
        position: 'bottom-right',
        isClosable: true,
      });
      setuserList([]);
    });
  };

  const getTxData = async (id, pagesNumber) =>{
    setStatusLoading(true);
    if(searchJenisTx==='semua'){
      await axios.get(URL_WEB_BACKEND+'/getalltxbmns/'+id+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            setShowTxDetail(res.data.txbmns);
            setAllPageNumber(res.data.pages);
            setIsPagination(true);
          }else{
            setIsPagination(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setIsPagination(false);setStatusLoading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }else{
      await axios.get(URL_WEB_BACKEND+'/cari/txbmns/'+idDetail+'/'+searchJenisTx+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            setShowTxDetail(res.data.txbmns);
            setAllPageNumber(res.data.pages);
            setIsPagination(true);
          }else{
            setIsPagination(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setIsPagination(false);setStatusLoading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }
  }

  const getCetakTxData = async (id) =>{
    setStatusLoading(true);
      await axios.get(URL_WEB_BACKEND+'/gettxbmnsbydaterange/'+id+'/'+new Date(filterTanggalMulai.getTime() - filterTanggalMulai.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ')+'/'+new Date(filterTanggalSampai.addDays(1).getTime() - filterTanggalSampai.addDays(1).getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '), {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            setShowCetakTxDetail(res.data);
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusLoading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const refreshData = async (pagesNumber) =>{
      setStatusLoading(true);
      await axios.get(URL_WEB_BACKEND+'/getalltxbmns/'+idDetail+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            onRefresh();
            setTimeout(() => {
              setShowTxDetail(res.data.txbmns);
              setAllPageNumber(res.data.pages);
              setIsPagination(true);
            }, 100);
          }else{
            setIsPagination(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusLoading(false);
          setIsPagination(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const exeEditDetail = async () =>{
    const formDataEdit = new FormData();
    formDataEdit.append('noreg', detail.noreg)
    formDataEdit.append('nama', detail.nama)
    formDataEdit.append('tanggal', detail.tanggal)
    formDataEdit.append('jumlah', jumlah_sesudah)
    formDataEdit.append('satuan', detail.satuan)
    formDataEdit.append('jenis', detail.jenis)
    formDataEdit.append('tersedia', tersedia)
    formDataEdit.append('kondisi', kondisi)
    formDataEdit.append('sumber', detail.sumber)
    formDataEdit.append('in_id_user', parseInt(in_id_user_sesudah))
    formDataEdit.append('keterangan', detail.keterangan)
    formDataEdit.append('verifid', detail.verifid)
      await axios.post(URL_WEB_BACKEND+'/updatebmns/'+idDetail, formDataEdit, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            setStatusUploading(false);
            refreshData(currentPageNumber);
            showInfoDialog('Membuat Data Transaksi','Data Berhasil Ditambah.', 0);
          }else{
            setStatusUploading(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Edit Data BMN dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusUploading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Edit Data BMN dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const exeAddTx = async () =>{
    setStatusUploading(true);setStatusConfExeDialog(false);
    const formDataAdd = new FormData();
    formDataAdd.append('id_bmn', idDetail)
    formDataAdd.append('jenis', addJenisTx)
    formDataAdd.append('tanggal', new Date(tanggalTx.getTime() - tanggalTx.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formDataAdd.append('jumlah_sebelum', detail.jumlah)
    formDataAdd.append('jumlah', jumlah_transaksi)
    formDataAdd.append('jumlah_sesudah', jumlah_sesudah)
    formDataAdd.append('user_fullname_sebelum', fullname_sebelum)
    formDataAdd.append('user_fullname_sesudah', fullname_sesudah)
    formDataAdd.append('kondisi', kondisi)
    formDataAdd.append('keterangan', keterangan)
    formDataAdd.append('filenya', filenya)
    formDataAdd.append('verifid', detail.verifid)
      await axios.post(URL_WEB_BACKEND+'/createtxbmns', formDataAdd, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===201){
            exeEditDetail();
          }else{
            setStatusUploading(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Edit Data BMN dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusUploading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Edit Data BMN dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);
  }

  function showTfInDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusTransferInternDialog(true);
  }

  function showTfExDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusTransferExternDialog(true);
  }

  function showPenambahanDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusPenambahanDialog(true);
  }

  function showPenggunaanDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusPenggunaanDialog(true);
  }

  function showPemanfaatanDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusPemanfaatanDialog(true);
  }

  function showPenonaktifanDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusPenonaktifanDialog(true);
  }

  function showCetakDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusCetakDialog(true);
  }

  const viewTfInDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusTransferInternDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
            <Box w='100%'>
                      <FormControl id={"tfin"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"tfin"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Input id={"tfin"+idDetail+6} placeholder="Masukkan pengoleh" borderRadius="16px" value={fullname_sebelum} disabled />
                        <FormLabel>Penerima</FormLabel>
                        <Select id={"tfin"+idDetail+11} aria-label="Default" value={ in_id_user_sesudah } onChange={ (e) => {
                          let name = userList.filter(user => (user.value === e.target.value));
                          setIn_id_user_sesudah(e.target.value);
                          setFullname_sesudah(name[0].label)}}>
                          {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                      <FormControl id={"tfin"+0+2}>
                        <FormLabel>Keterangan Tambahan</FormLabel>
                        <Textarea minH='100' id={"tfin"+idDetail+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_transaksi(detail.jumlah);setJumlah_sesudah(detail.jumlah);setTersedia(1);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    if(parseInt(jumlah_transaksi)>parseInt(detail.jumlah)){
                      toast({
                        status: 'error',
                        title: 'Proses Gagal',
                        description: 'Jumlah transaksi barang tidak boleh melebihi jumlah barang saat ini yaitu '+detail.jumlah,
                        position: 'bottom-right',
                        isClosable: true})
                    }else{
                      setStatusTransferInternDialog(false);showConfExeDialog('Transfer Internal','Lakukan transfer internal pada aset '+detail.nama+'?')
                    }
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusTransferInternDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewTfExDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusTransferExternDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
            <Box w='100%'>
                      <FormControl id={"tfex"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"tfex"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Input id={"tfex"+idDetail+6} placeholder="Masukkan pengoleh" borderRadius="16px" value={fullname_sebelum} disabled />
                        <FormLabel>Penerima</FormLabel>
                        <Input id={"tfex"+idDetail+6} placeholder="Masukkan penerima" borderRadius="16px" value={fullname_sesudah} onChange={ (e) => setFullname_sesudah(e.target.value) } />
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                      <FormControl id={"tfex"+0+2}>
                        <FormLabel>Keterangan Tambahan</FormLabel>
                        <Textarea minH='100' id={"tfex"+idDetail+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_transaksi(detail.jumlah);setJumlah_sesudah(0);setTersedia(0);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    if(parseInt(jumlah_transaksi)>parseInt(detail.jumlah)){
                      toast({
                        status: 'error',
                        title: 'Proses Gagal',
                        description: 'Jumlah transaksi barang tidak boleh melebihi jumlah barang saat ini yaitu '+detail.jumlah,
                        position: 'bottom-right',
                        isClosable: true})
                    }else{
                      setStatusTransferExternDialog(false);showConfExeDialog('Transfer Eksternal','Lakukan transfer eksternal pada aset '+detail.nama+'?')
                    }
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusTransferExternDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewPenambahanDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusPenambahanDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
                    <Box w='100%'>
                      <FormControl id={"penambh"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Jumlah Barang Transaksi</FormLabel>
                        <Input type='number' id={"penambh"+idDetail+4} placeholder="Masukkan jumlah barang transaksi" borderRadius="16px" value={jumlah_transaksi} onChange={ (e) => setJumlah_transaksi(e.target.value) } />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"penambh"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Select id={"penambh"+idDetail+10} aria-label="Default" value={ in_id_user_sebelum } onChange={ (e) => {
                          let name = userList.filter(user => (user.value === e.target.value));
                          setIn_id_user_sebelum(e.target.value);
                          setFullname_sebelum(name[0].label)}}>
                          {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Penerima</FormLabel>
                        <Input id={"tfex"+idDetail+6} placeholder="Masukkan penerima" borderRadius="16px" value={fullname_sesudah} onChange={ (e) => setFullname_sesudah(e.target.value) } disabled />
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                      <FormControl id={"penambh"+0+2}>
                        <FormLabel>Keterangan Tambahan</FormLabel>
                        <Textarea minH='100' id={"penambh"+idDetail+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_sesudah(parseInt(detail.jumlah)+parseInt(jumlah_transaksi));setTersedia(1);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    setStatusPenambahanDialog(false);showConfExeDialog('Penambahan','Lakukan penambahan pada aset '+detail.nama+' sebanyak '+jumlah_transaksi+'?')
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusPenambahanDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewPenggunaanDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusPenggunaanDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
                    <Box w='100%'>
                      <FormControl id={"penggunaan"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Jumlah Barang Transaksi</FormLabel>
                        <Input type='number' id={"penggunaan"+idDetail+4} placeholder="Masukkan jumlah barang transaksi" borderRadius="16px" value={jumlah_transaksi} onChange={ (e) => setJumlah_transaksi(e.target.value)} />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"penggunaan"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Input id={"penggunaan"+idDetail+4} placeholder="Masukkan pengoleh" borderRadius="16px" value={fullname_sebelum} disabled />
                        <FormLabel>Penerima</FormLabel>
                        <Select id={"penggunaan"+idDetail+11} aria-label="Default" value={ in_id_user_sesudah } onChange={ (e) => {
                          let name = userList.filter(user => (user.value === e.target.value));
                          setFullname_sesudah(name[0].label)}}>
                          {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                      <FormControl id={"penggunaan"+0+2}>
                        <FormLabel>Keterangan Tambahan</FormLabel>
                        <Textarea minH='100' id={"penggunaan"+idDetail+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_sesudah(parseInt(detail.jumlah)-parseInt(jumlah_transaksi));setTersedia(1);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    if(parseInt(jumlah_transaksi)>parseInt(detail.jumlah)){
                      toast({
                        status: 'error',
                        title: 'Proses Gagal',
                        description: 'Jumlah transaksi barang tidak boleh melebihi jumlah barang saat ini yaitu '+detail.jumlah,
                        position: 'bottom-right',
                        isClosable: true})
                    }else{
                      setStatusPenggunaanDialog(false);showConfExeDialog('Penggunaan','Gunakan aset '+detail.nama+' sebanyak '+jumlah_transaksi+'?')
                    }
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusPenggunaanDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewPemanfaatanDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusPemanfaatanDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
                    <Box w='100%'>
                      <FormControl id={"pemanfaat"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"pemanfaat"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Input id={"pemanfaat"+idDetail+4} placeholder="Masukkan pengoleh" borderRadius="16px" value={fullname_sebelum} disabled />
                        <FormLabel>Penerima</FormLabel>
                        <Select id={"pemanfaat"+idDetail+11} aria-label="Default" value={ in_id_user_sesudah } onChange={ (e) => {
                          let name = userList.filter(user => (user.value === e.target.value));
                          setIn_id_user_sesudah(e.target.value);
                          setFullname_sesudah(name[0].label)}}>
                          {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Keterangan Detail Pemanfaatan</FormLabel>
                        <Textarea minH='100' id={"pemanfaat"+idDetail+11} placeholder="Masukkan keterangan detail pemanfaatan" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_transaksi(detail.jumlah);setJumlah_sesudah(detail.jumlah);setTersedia(1);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null||keterangan===''){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    if(parseInt(jumlah_transaksi)>parseInt(detail.jumlah)){
                      toast({
                        status: 'error',
                        title: 'Proses Gagal',
                        description: 'Jumlah transaksi barang tidak boleh melebihi jumlah barang saat ini yaitu '+detail.jumlah,
                        position: 'bottom-right',
                        isClosable: true})
                    }else{
                      setStatusPemanfaatanDialog(false);showConfExeDialog('Pemanfaatan','Lakukan pemanfaatan pada aset '+detail.nama+'?')
                    }
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusPemanfaatanDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewPenonaktifanDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusPenonaktifanDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
                    <Box w='100%'>
                      <FormControl id={"penonak"+idDetail+1} isRequired>
                        <FormLabel>Tanggal Transaksi</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          date={tanggalTx}
                          onDateChange={setTanggalTx}
                        />
                        <FormLabel>Kondisi</FormLabel>
                        <Select id={"penonak"+idDetail+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                          {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Pengoleh</FormLabel>
                        <Select id={"penonak"+idDetail+10} aria-label="Default" value={ in_id_user_sebelum } onChange={ (e) => {
                          let name = userList.filter(user => (user.value === e.target.value));
                          setIn_id_user_sebelum(e.target.value);
                          setFullname_sebelum(name[0].label)}}>
                          {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                        </Select>
                        <FormLabel>Penerima</FormLabel>
                        <Input id={"penonak"+idDetail+6} placeholder="Masukkan penerima" borderRadius="16px" value={fullname_sesudah} onChange={ (e) => setFullname_sesudah(e.target.value) } />
                        <FormLabel>File Administratif</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+1+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                      </FormControl>
                      <FormControl id={"penonak"+0+2}>
                        <FormLabel>Keterangan Tambahan</FormLabel>
                        <Textarea minH='100' id={"penonak"+idDetail+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      </FormControl>
                    </Box>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {
                  setJumlah_transaksi(detail.jumlah);setJumlah_sesudah(detail.jumlah);setTersedia(0);
                  if(tanggalTx===''||jumlah_transaksi===''||kondisi===''||fullname_sebelum===''||fullname_sesudah===''||filenya===null){
                    toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true})
                  }else{
                    if(parseInt(jumlah_transaksi)>parseInt(detail.jumlah)){
                      toast({
                        status: 'error',
                        title: 'Proses Gagal',
                        description: 'Jumlah transaksi barang tidak boleh melebihi jumlah barang saat ini yaitu '+detail.jumlah,
                        position: 'bottom-right',
                        isClosable: true})
                    }else{
                      setStatusPenonaktifanDialog(false);showConfExeDialog('Penonaktifan','Lakukan penonaktifan pada aset '+detail.nama+'?');
                    }
                  }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusPenonaktifanDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const handleCetakKartuStok = useReactToPrint({content: () => componentToPrintRef.current,});

  const viewCetakTxDialog = () => {
    return (
      <Modal closeOnOverlayClick={true} isCentered isOpen={statusCetakDialog} size='full'>
        <ModalContent>
          <div ref={componentToPrintRef}>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>Nama Barang&nbsp;&nbsp;:&nbsp;&nbsp;{detail.nama}</Text><br/>
                <Text as='b'>Satuan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{detail.satuan}</Text>
              </Box>
              <Box w='100%'>
              <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                <Box w='100%'>
                <Text>Mulai :
                  <SingleDatepicker
                    name="date-input"
                    date={filterTanggalMulai}
                    onDateChange={setFilterTanggalMulai}/></Text>
                </Box>
                <Box w='100%'>
                <Text>Sampai : 
                  <SingleDatepicker
                    name="date-input"
                    date={filterTanggalSampai}
                    onDateChange={setFilterTanggalSampai}/></Text>
                </Box>
              </Grid>
              </Box>
            </Grid><br/><HSeparator/><br/>
            {showCetakTxDetail.length<1?<Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada data transaksi pada rentang tanggal ini.</>}</Center>:
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Jenis Transaksi</Th>
                  <Th>Tanggal</Th>
                  <Th>Pengoleh</Th>
                  <Th>Penerima</Th>
                  <Th isNumeric>Jml Sebelum</Th>
                  <Th isNumeric>Jml Transaksi</Th>
                  <Th isNumeric>Jml Sesudah</Th>
                  <Th>Kondisi</Th>
                  <Th>Keterangan</Th>
                </Tr>
              </Thead>
              <Tbody>
              {showCetakTxDetail.map((datatx, index) => (
                <Tr key={index+1}>
                    <Td>{datatx.jenis}</Td>
                    <Td>{convertDateTime(datatx.tanggal)}</Td>
                    <Td>{datatx.user_fullname_sebelum}</Td>
                    <Td>{datatx.user_fullname_sesudah}</Td>
                    <Td isNumeric>{datatx.jumlah_sebelum}</Td>
                    <Td isNumeric>{datatx.jumlah_sebelum>datatx.jumlah_sesudah?<> -</>:<>{datatx.jumlah_sebelum===datatx.jumlah_sesudah?<></>:<> +</>}</>}{datatx.jumlah}</Td>
                    <Td isNumeric><Text as='b'>{datatx.jumlah_sesudah}</Text></Td>
                    <Td>{datatx.kondisi}</Td>
                    <Td>{datatx.keterangan}</Td>
                  </Tr>
              ))}
              </Tbody>
              </Table>}
            </ModalBody>
            </div>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick = {() => handleCetakKartuStok()}>
                Cetak
              </Button>
              <Button onClick = {() => {setStatusCetakDialog(false)}}>Batal</Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
            <Button colorScheme='red' mr={3} onClick = {() => exeAddTx()}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setFilenya(null);setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      {viewTfInDialog()}{viewTfExDialog()}{viewPenambahanDialog()}{viewPenggunaanDialog()}{viewPemanfaatanDialog()}{viewPenonaktifanDialog()}{viewInfoDialog()}{viewConfExeDialog()}{viewUploadingDialog()}{viewCetakTxDialog()}
      <Box borderRadius="xl" boxShadow="lg" p="20px" bg={bgGradient}>
        <Stack direction='row'>  
          <Text
            color={textColorPrimary}
            fontSize='2xl'
            fontWeight='700'
            lineHeight='100%'>
            Riwayat Transaksi
          </Text>
          <Spacer/>
          {detail.jenis==='tetap'?<Select maxW='200px' id={"add"+idDetail+123} aria-label="Default" value={ addJenisTx } onChange={ (e) => setAddJenisTx(e.target.value) }>
            {tetapJenisTxOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
          </Select>:
          <Select maxW='200px' id={"add"+idDetail+123} aria-label="Default" value={ addJenisTx } onChange={ (e) => setAddJenisTx(e.target.value) }>
            {persediaanJenisTxOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
          </Select>}
          <Button
          variant='action'
          onClick={()=>{
            if(addJenisTx==='transfer_internal'){
              showTfInDialog('Transfer Internal','Pastikan semua data sesuai, Transfer internal aset tetap ke bagian lain dalam satu UPT.');
            }else if(addJenisTx==='transfer_eksternal'){
              showTfExDialog('Transfer Eksternal','Pastikan semua data sesuai, Transfer eksternal aset tetap ke UPT lain.');
            }else if(addJenisTx==='penambahan'){
              showPenambahanDialog('Penambahan','Pastikan semua data sesuai, Penambahan jumlah persediaan maupun tetap.');
            }else if(addJenisTx==='penggunaan'){
              showPenggunaanDialog('Penggunaan','Pastikan semua data sesuai, Penggunaan persediaan dalam jumlah tetrtentu sesuai kebutuhan.');
            }else if(addJenisTx==='pemanfaatan'){
              showPemanfaatanDialog('Pemanfaatan','Pastikan semua data sesuai, Pemanfaatan aset tetap untuk keperluan tertentu.');
            }else{
              showPenonaktifanDialog('Penonaktifan','Pastikan semua data sesuai, Penonaktifan aset tetap maupun persediaan yang telah rusak berat / habis.');
            }}}>
            <Text
              fontSize='lg'
              as='b'>
                + Transaksi
            </Text>
          </Button>
          {detail.jenis==='tetap'?<></>:<Button
          variant='action'
          onClick={()=>{getCetakTxData(detail.id);showCetakDialog('Kartu Stok Barang Persediaan','Lembaga Pemasyarakatan Kelas I Semarang')}}>
            <Text
              fontSize='lg'
              as='b'>
                Cetak Kartu Stok
            </Text>
          </Button>}
        </Stack>
      </Box>
      <Box mt='10px' w='100%'>
      <Stack direction='row'>
        <Text
          as='b'
          ms='10px'
          me='15px'
          alignSelf='center'
          fontSize='md'>
           FILTER
        </Text>
        <Select id={"search"+idDetail+123} aria-label="Default" value={ searchJenisTx } onChange={ (e) => setSearchJenisTx(e.target.value) }>
          {jenisTxOPtSearch.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
        </Select>
      </Stack>
      {showTxDetail.length<1?<Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada data transaksi.</>}</Center>:
      <Table variant="striped" colorScheme="gray">
        <TableCaption>
                {isPagination&&allPageNumber>=1?
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber - 1);getTxData(detail.id, currentPageNumber - 1);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
                    <Button>{currentPageNumber}</Button>
                    {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber + 1);getTxData(detail.id, currentPageNumber + 1);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
                  </Grid>:<Text as='b'>akhir dari tabel.</Text>
                }
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Jenis Transaksi</Th>
            <Th>Tanggal</Th>
            <Th>Pengoleh</Th>
            <Th>Penerima</Th>
            <Th isNumeric>Jml Sebelum</Th>
            <Th isNumeric>Jml Transaksi</Th>
            <Th isNumeric>Jml Sesudah</Th>
            <Th>Kondisi</Th>
            <Th>Keterangan</Th>
            <Th>File Administratif</Th>
          </Tr>
        </Thead>
        <Tbody>
        {showTxDetail.map((datatx, index) => (
          <Tr key={index+1}>
              <Td>{datatx.jenis}</Td>
              <Td>{convertDateTime(datatx.tanggal)}</Td>
              <Td>{datatx.user_fullname_sebelum}</Td>
              <Td>{datatx.user_fullname_sesudah}</Td>
              <Td isNumeric>{datatx.jumlah_sebelum}</Td>
              <Td isNumeric>{datatx.jumlah_sebelum>datatx.jumlah_sesudah?<> -</>:<>{datatx.jumlah_sebelum===datatx.jumlah_sesudah?<></>:<> +</>}</>}{datatx.jumlah}</Td>
              <Td isNumeric><Text as='b'>{datatx.jumlah_sesudah}</Text></Td>
              <Td>{datatx.kondisi}</Td>
              <Td>{datatx.keterangan}</Td>
              <Td><Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/txbmns/"+detail.verifid+'/'+datatx.namafile, datatx.namafile)}><DownloadIcon/> LIHAT DISINI</Button></Td>
            </Tr>
        ))}
        </Tbody>
        </Table>}
      </Box>
    </Card>
  );
}
