// Chakra imports
import {
  Flex,
  useColorModeValue,
  useToast,
  Text,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Button,
  Stack,
  Spacer,
  Center,
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
// Custom components
import Card from "components/card/Card.js";
import React, {useState, useEffect} from "react";
import axios from "axios";
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function SubnosukelsTable({refreshing}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const cekadmin = decryptData(localStorage.getItem("authrolesitu"));
  const [subnosukelData, SetSubnosukelData] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [kodeinduk, setKodeinduk] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [nomor, setNomor] = useState('');
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const toast = useToast();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  useEffect(() => {
    if (refreshing || subnosukelData.length < 1 || subnosukelData === undefined) {
      getSubNosukel();
    }
  },[refreshing]);

  const getSubNosukel = async () => {
      SetSubnosukelData([]);
      await axios.get(URL_WEB_BACKEND+'/subnosukels', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        SetSubnosukelData(response.data);
      }).catch(err => {
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data sub nomor kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
  }

  const getSubNosukelwithSearch = async (searchClue) => {
    if(searchClue === ''){
      getSubNosukel();
    }else{
      SetSubnosukelData([]);
    await axios.get(URL_WEB_BACKEND+'/cari/subnosukels/kodeinduk/'+searchClue, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      SetSubnosukelData(response.data);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Tak ada data yang dicari.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
    }
  }

  const exeEdit = async () =>{
    setStatusEditDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin' || cekadmin === 'um'){
      await axios.put(URL_WEB_BACKEND+'/subnosukels/'+idSelectedData,{
        kodeinduk: kodeinduk.toUpperCase(),
        nomor: nomor,
        keterangan: keterangan.toUpperCase()
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getSubNosukel();
            showInfoDialog('Berhasil', 'Data Berhasil Diperbarui', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Pembaruan','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
    if(cekadmin === 'admin' || cekadmin === 'um'){
      await axios.delete(URL_WEB_BACKEND+'/subnosukels/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getSubNosukel();
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeEdit();
                  }else{
                    exeDelete();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"nosukel"+idSelectedData} isRequired>
                <FormLabel>Kode Induk</FormLabel>
                <Input id={"nosukel"+idSelectedData+1} placeholder="Masukkan kode nomor surat" borderRadius="16px" value={kodeinduk} onChange={ (e) => setKodeinduk(e.target.value) } />
                <FormLabel>Nomor</FormLabel>
                <Input type='number' id={"nosukel"+idSelectedData+3} placeholder="Masukkan total nomor terpakai" borderRadius="16px" value={nomor} onChange={ (e) => setNomor(e.target.value) } />
                <FormLabel>Keterangan</FormLabel>
                <Input id={"nosukel"+idSelectedData+2} placeholder="Masukkan keterangan" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(kodeinduk === '' || keterangan === '' || nomor === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Sub Nomor Surat','Simpan pembaruan sub nomor surat ini?',1)}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Flex direction='column' pe='44px'>
        {viewEditDialog()}{viewInfoDialog()}{viewConfExeDialog()}
        <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "5px", "2xl": "5px" }}>
            Daftar Sub Kode Nomor Surat
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            textAlign='start'>
            Kelola sub kode nomor surat keluar.
          </Text>
          <Table variant='unstyled'>
            <Thead><Tr>
              <Td><Input variant='filled' id={"search"+111} placeholder="Cari berdasar kode induk" borderRadius="16px" onChange={ (e) => getSubNosukelwithSearch(e.target.value) } /></Td>
            </Tr></Thead>
            <Box ms='-6' me='-16' overflowX="auto" maxH='300'>
                <Tbody>
                  {subnosukelData.length<1
                  ? <Tr><Td><Center mb={6}>Belum ada daftar kode nomor surat keluar.</Center></Td></Tr>
                  : <>{subnosukelData.map((datasubnosukel, index) => (
                    <Tr key={index+1}>
                        <Td>
                          <Stack me='0' direction="row" p="2" boxShadow="lg" borderRadius="lg">
                            <Stack direction="row" >
                              <Stack direction="column">
                                <Text fontSize={{base: 'xl'}} fontWeight="bold">{datasubnosukel.kodeinduk} . {datasubnosukel.nomor}</Text>
                                <Text mt={4} fontSize={14}>
                                  {datasubnosukel.keterangan}
                                </Text>
                              </Stack>
                            </Stack>
                            <Spacer />
                            <Stack direction={{ base: 'column', md: 'row' }}>
                              <Button variant="outline" size='sm' colorScheme="red" onClick={()=>{
                                setIdSelectedData(datasubnosukel.id);
                                setTimeout(() => {
                                  showConfExeDialog('Hapus Sub Kode Nomor Surat','Yakin ingin menghapus subkode '+datasubnosukel.kodeinduk+' . '+datasubnosukel.nomor+' ?',2)
                                }, 200);
                                }}>HAPUS</Button>
                              <Button colorScheme="blue" size='sm' onClick={()=>{
                                setIdSelectedData(datasubnosukel.id);setKodeinduk(datasubnosukel.kodeinduk);setNomor(datasubnosukel.nomor);setKeterangan(datasubnosukel.keterangan);
                                setTimeout(() => {
                                  showEditDialog('Edit Sub Kode Nomor Surat','Perbarui sub kode nomor surat agar sesuai.')
                                }, 200);
                                }}>EDIT</Button>
                            </Stack>
                          </Stack>
                        </Td>
                    </Tr>
                  ))}</>
                  }
                </Tbody>
              </Box>
            </Table>
        </Flex>
      </Flex>
    </Card>
  );
}
