import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import AuthRouter from './AuthRouter'
import { HashRouter as Routerrnya, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Routerrnya>
        <Switch>
          <AuthRouter/>
        </Switch>
      </Routerrnya>
    </React.StrictMode>
  </ChakraProvider>
  ,document.getElementById("root")
);

serviceWorkerRegistration.register();